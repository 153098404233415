import React from "react";

import Layout from "../containers/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout screen>
    <SEO title="404: Not found" />
    <div className="flex justify-center items-center flex-col h-full">
      <h1 className="font-display text-lg sm:text-2xl lg:text-4xl text-center">
        You came to the wrong place.
        <br /> There's nothing here.
      </h1>
      <Link
        className={"block hover:text-gray-600 border-b-1 border-gray-200 text-white text-sm"}
        to="/"
      >
        Go home, you're drunk.
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage;
